import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';
import config from '../website-config';
import { Link } from 'gatsby';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">About</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>
                  {config.description}
                </h5>
                <p>
                  ビジネスパーソンをはじめ経済活動にかかわる人たちは日々多くの問題について考え、多くの課題に立ち向かい、実際に解決しています。
                  無数の課題を潜り抜けた百戦錬磨のエリートビジネスパーソン、投資家、経営者、研究者によって書かれた実用書・ビジネス書にはこうした日々の努力から得られた教訓が詰まっています。
                </p>
                <p>
                  こうした価値ある知見はもちろん読めば読むだけ実践すればするだけ読者の力になるわけですが、ここで二つ大きな問題があります。
                </p>
                <p>
                  一つは世に出る本が恐ろしく多いこと。
                </p>
                <p>
                  世にある書籍数はあまりに多いのでどれを読むべきか迷うこともありますし、読むべき本が多すぎて圧倒されてしまうこともありえます。
                </p>
                <p>
                  もう一つは本同士が互いに関連していること。
                </p>
                <p>
                  多くの書籍は、似たような話題を扱っていたり似たような課題に対して別の視点から考察したりということをしています。おなじ課題について、さまざまな書籍から違う観点を取り入れればそれだけ理解を深めることができますが、どういった本を併せて読めば効率的に吸収できるのか分からなければ絵に描いた餅です。
                </p>
                <p>
                  このような背景から当サイトでは主なコンテンツとして次の二つを提供しています。
                </p>
                <ul>
                  <li>話題になった本・読んで参考になった本の要約や解説</li>
                  <li>
                    目的・お題に沿って複数の書籍を紹介して、どの本を読むべきか決める手助けをする
                    <Link to="/tags/書籍案内">書籍案内</Link>
                  </li>
                </ul>
                <p>
                  できるだけ多くの書籍の要約・解説を紹介することで、出版される大量の本から読者が「何となく分かる」本を増やし、<Link to="/tags/書籍案内">書籍案内</Link>を通じてさまざまな本を比較・整理をしていきます。
                </p>
                <p>
                  いくら本を読むほうだとはいっても世に出る書籍の数に比べれば、自分の筆の遅さを考えれば全く足りませんが、それでも読者のみなさまの選書の助けになれば幸いです。
                </p>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
